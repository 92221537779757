import { useEffect, useState } from "react";

import { ChosenOptions } from "../BookingVehicleInfoCard/BookingVehicleInfoCard.props";
import React from "react";
import { SelectVehicleExtrasProps } from "./SelectVehicleExtras.props";
import { SelectedVehicleExtraOptions } from "../../../@types/VehicleOptions";
import VehicleExtraOptions from "../../UI/VehicleExtraOptions";
import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";
import { useTranslation } from "react-i18next";

SelectVehicleExtras.defaultProps = {
  onChange: () => {},
};
function SelectVehicleExtras({
  options,
  label,
  onChange,
  values = {},
  showExtras,
}: SelectVehicleExtrasProps): JSX.Element {
  const { t: translate } = useTranslation();
  const [selectedOptions, setSelectedOptions] =
    useState<ChosenOptions["options"]>(values);
  useEffect(() => {
    onChange(selectedOptions); // Send the selected option back to the parent
  }, [selectedOptions]);

  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
      // style={{ width: "308px" }}
    >
      <AppInputSwitcher
        required={true}
        className="w-[310%]"
        label={translate(label)}
        value={selectedOptions["optionName"] || ""} // Show the selected optionName
        options={options.map((el) => ({
          label: `€ ${el.pricePerDayInUSD} - ${el.optionName}`,
          value: el,
        }))}
        type="select"
        variant="with-search"
        onchange={(el) => {
          setSelectedOptions((prevState) => ({
            ...prevState,
            optionName: `€ ${el.pricePerDayInUSD} - ${el.optionName}`,
            name: el.optionName,
            quantity: 1,
            price: el.pricePerDayInUSD,
          }));
        }}
      />
    </div>
  );
}
export { SelectVehicleExtras as default };
export { SelectVehicleExtras };
